<template>
  <div class="Secretary">
    <div class="navTop">
      <div class="left">
        <img :src="require('@/assets/main/2.png')">
        <div>{{ this.$store.getters.info.fristName }}</div>
      </div>
      <div class="right center click" @click="$go('/SecretaryDetail')">查看详情></div>
    </div>

    <div class="notes">
      <div>工作简介：</div>
      <div class="twoLine rich" v-html="$setContent(fristNotes)" />
    </div>

    <div class="selectedOut">
      <div
        class="click"
        :class="{ selected: selected == 0 }"
        @click="changeSelected(0)"
      >
        工作动态
      </div>
      <div
        class="click"
        :class="{ selected: selected == 1 }"
        @click="changeSelected(1)"
      >
        工作纪实
      </div>
    </div>
    <div class="line" />
    <myContent ref="contents" />
  </div>
</template>

<script>
import myContent from './components/Content.vue'
export default {
  components: { myContent },
  data() {
    return {
      selected: 0,
      param: {},
      total: 0,
      fristNotes: this.$store.getters.info.fristNotes,
      loading: false
    }
  },
  watch: {
    $route() {
      this.init()
    }
  },
  created() {
    this.init()
  },
  destroyed() {},

  methods: {
    changeSelected(select) {
      if (select === this.selected) return
      else {
        this.$changeParam({ selected: select }, true)
      }
    },
    init() {
      this.param = this.$getParams()
      this.selected = this.param.selected * 1 || 0
      this.$nextTick(() => {
        const contents = this.$refs.contents
        contents.page = this.param.page || 1
        this.$parent.loading = true
        this.$refs.contents.getData()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.Secretary {
  margin: 0 auto;
  width: 795px;
  margin-right: 26px;
  .notes{
    margin-top: 24px;
    background: #F6F8FA;
    padding:21px 24px;
    &>div:nth-child(1){
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #333333;
      line-height: 32px;
    }
    &>div:nth-child(2){
      font-size: 16px;
      font-family: Microsoft YaHei;
      color: #717172;
      line-height: 32px;
    }
  }
  .navTop {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left {
      display: flex;
      align-items: center;
      img {
        margin-right: 7px;
        width: 30px;
        height: 30px;
      }
      & > div {
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: var(--theme);
      }
    }
    .right {
      width: 96px;
      height: 32px;
      background: #ffffff;
      border: 1px solid var(--theme);
      border-radius: 4px;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: var(--theme);
    }
  }

  .selectedOut {
    padding-top: 15px;
    display: flex;
    .click {
      padding-bottom: 10px;
    }
    div {
      font-size: 20px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #666666;
      margin-right: 29px;
    }
    .selected {
      font-weight: bold;
      color: var(--theme);
      border-bottom: 2px solid var(--theme);
    }
  }
  .searchK {
    margin-bottom: 29px;
    display: flex;
    ::v-deep {
      .el-input__inner {
        height: 48px;
      }
    }
    .text {
      width: 120px;
      height: 48px;
      background: var(--theme);
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
    }
  }
  .searchTextOut {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    margin-bottom: 15px;
    .searchText {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: var(--theme);
    }
  }
  .line {
    height: 1px;
    background: #e0e0e0;
  }
}
</style>

<template>
  <div v-loading="loading" class="Content">

    <div v-if="contentData.length" class="content">
      <!-- 1文章、2视频、3公告、4三务公开、5精准扶贫  145一类 -->
      <div>
        <div v-for="item in contentData" :key="item.id" class="content-item" :class="{click:type==1}" @click="goDetail(item.id)">
          <div v-if="type===1" class="content-text">
            <div class="contentTitle oneLine">{{ item.title }}</div>
            <div class="rich" v-html="$setContent(item.context)" />
            <div style="line-height: 32px;">{{ item.createTime }} | 书记：{{ item.firstUser }}</div>
          </div>
          <div v-else class="content-text">
            <div class="contentTitle2Out">
              <div class="contentTitle2 oneLine">书记：{{ item.firstUser }}</div>
              <div v-if="item.createTime" class="time">{{ item.createTime.split(' ')[0] }}</div>
            </div>
            <div class="rich" v-html="$setContent(item.context)" />
          </div>
          <img v-if="item.thumbnail" :src="$beforeUrl(item.thumbnail)">
        </div>
      </div>
      <div class="center">
        <el-pagination
          background
          layout="prev, pager, next"
          :total="total"
          :page-size="limit"
          class="pagination"
          :current-page="page*1"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
    <div
      v-else-if="$parent.searchText"
      style="flex: 1; font-size: 100px; height: 500px"
      class="colCenter it"
    >
      <img :src="require('@/assets/home/first/empty.png')">
      <span>暂无内容</span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      page: 1, // 当前页数
      contentData: [],
      total: 0,
      loading: false
    }
  },
  computed: {
    limit() {
      return 5
    },
    type() {
      return this.$parent.selected * 1 + 1
    }
  },
  mounted() {

  },
  methods: {
    handleCurrentChange(val) {
      this.$changeParam({ page: val })
    },
    goDetail(id) {
      if (this.type == 2) return
      this.$router.push({ path: '/SecretaryArticleDetail', query: { id }})
    },
    getData() {
      this.loading = true
      this.$store.dispatch('main/SecretaryGetFirstNewsList', { page: this.page, limit: this.limit, type: this.type }).then(res => {
        if (res.code === 0) {
          this.total = res.count
          this.contentData = res.data
          this.$parent.$parent.loading = false
        }
        this.loading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.Content {
  width: 100%;
  overflow: hidden;
  .title {
    border-bottom: 1px solid #e0e0e0;
    height: 41px;
    .text {
      font-size: 22px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      border-bottom: 4px solid var(--theme);
      height: 41px;
      display: inline-block;
    }
  }
  .content {
    .content-item {
      display: flex;
      // height: 124px;
      border-bottom: 1px dotted #eee;
      padding-top: 20px;
      padding-bottom: 21px;
      box-sizing: content-box;
      img {
        width: 200px;
        height: 134px;
        margin-left: 16px;
      }
      .content-text {
        flex: 1;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        div {
          width: 100%;
          display: block;
        }
        .contentTitle:nth-child(1) {
          font-size: 20px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #333333;
        }
        .contentTitle2Out{
          display: flex;
          align-items: center;
          .contentTitle2{
            font-size: 20px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #333333;
            flex:1;
            margin-right: 15px;
          }
          .time{
            width: max-content;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #999999;
          }
        }
        & > div:nth-child(2) {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          line-height: 26px;
          word-break:break-all;
          margin-top: 22px;
        }
        & > div:nth-child(3) {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #666666;
          margin-top: 19px;
        }
      }
    }
  }
  .pagination {
    padding-top: 72px;
    padding-bottom: 72px;
  }

}
</style>
